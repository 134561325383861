<template>
  <div class="container">
    <h1>Elfelejtett jelszó</h1>
    <div v-if="!done">
    <p>
      Amennyiben elfelejtetted jelszavad, vagy felhasználóneved, úgy a
      regisztrációkor használt email címed megadásával elküldünk neked egy
      levelet benne egy linkel, amit megnyitva megadhatod az új jelszavad! Az
      email tartalmazni fogja a felhasználóneved is!
    </p>
    <h2 class="label">E-mail cím</h2>
    <b-form-input
      :state="emailState"
      v-model="email"
      maxlength="100"
      placeholder="Írd be az email címed..."
    ></b-form-input>

    <b-form-invalid-feedback id="input-live-feedback">
      {{ emailStateText }}
    </b-form-invalid-feedback>

    <b-button class="mt-4" variant="primary" @click="sendEmail()"
      >Küldés</b-button
    >
    </div>
    <div v-if="done">
      <p>
      Az emailt elküldtük a {{ email }} címre. Amennyiben a
      levelünk nem érkezik meg 10 percen belül, nézd meg a levélszemét/spam
      mappában is. Ha továbbra sem találod, írj nekünk emailt az info@elektrogeek.hu címre.

      További szép napot!
      </p>
    </div>
  </div>
</template>

<script>
import { emailValidation } from "../util/validation";
import Api from "../util/api";

export default {
  name: "forgot",
  data() {
    return {
      email: "",
      emailState: null,
      emailStateText: "Érvénytelen email cím!",
      done: false
    };
  },
  watch: {
    email() {
      if (this.email.length != 0) {
        if (emailValidation(this.email)) {
          this.emailState = true;
        } else {
          this.emailState = false;
          this.emailStateText = "Érvénytelen email cím!";
        }
      } else {
        this.emailState = null;
      }
    },
  },
  methods: {
    sendEmail() {
      if (this.emailState) {
        this.$loading(true);
        let self = this;
        Api.forgot(this.email)
          .then(function (response) {
            self.$loading(false);
            if (!response.data) {
              self.done = true;
            }
            if (response.data == "1") {
              self.emailState = false;
              self.emailStateText = "Ezzel az email címmel még nem regisztráltak";
            } else if(response.data == "2") {
              self.emailState = false;
              self.emailStateText = "Az emailt már korábban elküldtük! Amennyiben nem érkezett meg levelünk, írj nekünk az info@elektrogeek.hu email címre!";
            } else if(response.data == "3") {
              self.makeToast(
                "Hiba!",
                "danger",
                "Váratlan hiba történt!"
              );
            }
          })
          .catch((err) => {
            self.$loading(false);
            console.log(err);
          });
      }
    },
    makeToast(title, variant, errorMessage) {
      this.$bvToast.toast(errorMessage, {
        title: title,
        autoHideDelay: 5000,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  max-width: 700px;
  margin: 10px auto;
  padding: 35px 35px 60px 35px;
  color: #444;
  background: white;
}
h1 {
  text-align: center;
}
h2 {
  margin-top: 30px;
  color: #777;
}
p {
  text-align: justify;
  padding: 15px;
  margin-top: 25px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: rgba(30, 130, 0, 0.15);
}
</style>